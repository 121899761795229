import { styled, withStyles } from '@material-ui/core/styles';
import Image from 'next/image';
import IconButton from '@material-ui/core/IconButton';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '20px',
  '& .event-card-title': {
    padding: '0px 10px',
  },
  '@media screen and (max-width: 780px)': {
    margin: '20px 10px',
  },
}));

export const CardContainer = styled('div')(() => ({
  padding: '10px 20px 0 0',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  '@media screen and (max-width: 780px)': {
    padding: '10px 0',
  },
}));

export const CardLink = styled('div')(() => ({
  cursor: 'pointer',
  margin: '0 20px 20px 0',
  textDecoration: 'none',
  borderRadius: '10px',
  border: 'solid 20px #fff',
  backgroundColor: '#fff',
  boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.16)',
  '@media screen and (min-width: 1100px)': {
    '&:last-child': {
      marginRight: '0px',
    },
  },
  '@media screen and (max-width: 780px)': {
    border: 'solid 10px #fff',
  },
}));

export const Img = styled(Image)({
  maxWidth: '35vw',
  borderRadius: '10px',
  '@media screen and (max-width: 780px)': {
    maxWidth: '100vw',
  },
});

export const ShareButton = withStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 99,
    right: 0,
    cursor: 'pointer',
    '& svg': {
      fill: 'white',
      width: '30px !important',
      height: '30px !important',
    },
  },
}))(IconButton);
