import { styled } from '@material-ui/core';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 10px 0 24px',
  position: 'absolute',
  top: '5px',
  left: 0,

  '& svg': {
    width: '50px',
    height: '50px',
  },
});
