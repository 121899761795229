import styled, { createGlobalStyle } from 'styled-components';

export const Group = styled.div`
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  margin: 0px;
`;

export const Text = styled.p`
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-family: 'Barlow';
  font-size: 1.2rem;
  @media screen and (min-width: 960px) {
    font-size: 1.5rem;
  }
`;

export const GlobalStyle = createGlobalStyle`
.hubspot-attendee-form.hs-form-45106069-e44c-4148-98e1-c8aeddfa30e5_77b1ad67-23e6-4ee7-ac83-ffebdcb9bc63 .hs-form-field label:not(.hs-error-msg){color:initial}
.hubspot-attendee-form.hs-form-45106069-e44c-4148-98e1-c8aeddfa30e5_77b1ad67-23e6-4ee7-ac83-ffebdcb9bc63 fieldset.form-columns-1 .input{margin-right:0}
.hubspot-attendee-form .hbspt-form{color:#fff;}
.hubspot-attendee-form .field.hs-form-field { margin-bottom: 12px}
.hubspot-attendee-form .field.hs-form-field label{color:#fff;margin-top:12px;margin-bottom: 12px}
.hubspot-attendee-form.hs-form select{-webkit-appearance: none; padding:0 10px;background-color:#000;color:#fff}
.hubspot-attendee-form .hs-form-checkbox label{color:#999;display:flex;align-items:center;justify-content:flex-start;margin-left:-18px}
.hubspot-attendee-form .field.hs-form-field label.hs-error-msg{color:red;font-size:12px;margin-bottom:12px}
.hubspot-attendee-form.hbspt-form .hs-form-required{display:none}

.hubspot-attendee-form .inputs-list input{margin:16px 0 0;height:initial}

.hubspot-attendee-form {
  max-width: 340px;
  margin: 0 auto;

  &.hs-form input, 
  &.hs-form select {
    border:1px #c9c9c9 solid;
    padding: 15px;
    border-radius: 12px;
    margin: 6px 0;
    width: 100%;
    background-color: #fff;
    color: #292929;
    font-size: 14px;
    &:focus {
      outline: none;
    }
  }

  .hs-fieldtype-intl-phone.hs-input .hs-input {
    margin: 6px 0;
  }

  .hs-input {
    width: 100%; 
  }

  input[id^="LEGAL_CONSENT"] {
    width: auto;
  }

  .inputs-list {
    padding: 0px;
  }

  .hs-error-msgs {
    margin: 0;
    position: absolute;
    margin-top: -8px;
  }

  & ::placeholder {
    color: #292929;
    font-weight: 300;
    font-size: 14px;
  }

  .inputs-list a {
    color: inherit;
  }

  .hs_submit input.hs-button {
    border: none;
    background-color: rgb(253,98,105);
    color:#fff;
    font-size: 22px;
    font-weight: bold;
    cursor:pointer;
    width:100%;
    border-radius: 50px;
    padding: 15px 0;
    margin: 20px 0;
    transition:all .3s ease-in;
    &:hover{
      background-color: rgb(240,74,81);
    }
  }
}
`;
