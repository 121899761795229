import { useState } from 'react';

import { Title } from '@components/core/Typography/index';
import { IEvent } from '@models/event.interface';
import LiveAnimation from '@components/core/LiveAnimation';
import BannerForm from '@components/pages/eventos/BannerForm';
import { Box } from '@material-ui/core';
import { ShareOutlined } from '@material-ui/icons';
import { Link } from '@components/ui';
import { useTracking } from '@hooks/tracking';
import { useUser } from '@hooks/user';
import { useAuth } from '@hooks/auth';
import { Container, CardContainer, CardLink, Img, ShareButton } from './styles';

import EventDetailsModal from '../EventDetailsModal';

export interface IEventCards {
  liveEvents: IEvent[];
  futureEvents: IEvent[];
  subscribeConferenceSuccess?: (slug: string) => void;
  onClickShare?: (eventSlug: string) => void;
}

const EventCards: React.FC<IEventCards> = ({
  liveEvents = [],
  futureEvents = [],
  onClickShare,
  subscribeConferenceSuccess,
}) => {
  const [isEventDetailsModalOpen, setIsEventDetailsModalOpen] = useState(false);
  const [actualEvent, setActualEvent] = useState<IEvent>({} as IEvent);

  const { user } = useUser();
  const { setShowModalLogin } = useAuth();
  const { track } = useTracking();

  const handleIsEventDetailsModalOpen = (event: IEvent) => {
    setActualEvent(event);
    setIsEventDetailsModalOpen(true);
  };

  const handleCloseEventDetailsModel = () => {
    setIsEventDetailsModalOpen(false);
  };

  const clickShare = (event: IEvent) => {
    if (user?._id) {
      track('event-share-button-clicked', {
        eventSlug: event.slug,
      });
      onClickShare(event.slug);
    } else {
      setShowModalLogin(true);
    }
  };

  return (
    <>
      <Container>
        {liveEvents.length > 0 && (
          <div>
            <Title className="event-card-title">
              <LiveAnimation />
              Acontecendo agora
            </Title>
            <CardContainer>
              {liveEvents.map(event => (
                <CardLink key={event.id}>
                  {onClickShare &&
                    process.env.NEXT_PUBLIC_CAMPAIGN_REGULATION_URI && (
                      <Box
                        position="relative"
                        onClick={() => clickShare(event)}
                      >
                        <ShareButton>
                          <ShareOutlined />
                        </ShareButton>
                      </Box>
                    )}
                  <Link
                    href={`${process.env.NEXT_PUBLIC_EVENTS_PLATFORM}${event.slug}?utm_source=platform`}
                    noDecoration
                  >
                    <Img
                      src={event.platformCard}
                      alt={event.name}
                      width="350px"
                      height="350px"
                      objectFit="cover"
                      quality="50"
                    />
                  </Link>
                </CardLink>
              ))}
            </CardContainer>
          </div>
        )}
        {futureEvents.length > 0 && (
          <div>
            <Title className="event-card-title">Inscrições Abertas</Title>
            <CardContainer>
              {futureEvents.map(event => {
                const BannerFormComponent = BannerForm[event.slug] as React.FC;

                return BannerFormComponent ? (
                  <BannerFormComponent key={event.id} />
                ) : (
                  <CardLink key={event.id}>
                    {onClickShare &&
                      process.env.NEXT_PUBLIC_CAMPAIGN_REGULATION_URI && (
                        <Box
                          position="relative"
                          onClick={() => clickShare(event)}
                        >
                          <ShareButton>
                            <ShareOutlined />
                          </ShareButton>
                        </Box>
                      )}
                    <Img
                      onClick={() => {
                        track('Clicked in event banner', {
                          eventName: event.name,
                        });
                        handleIsEventDetailsModalOpen(event);
                      }}
                      src={event.platformCard}
                      alt={event.name}
                      width="350px"
                      height="350px"
                      objectFit="cover"
                      quality="50"
                    />
                  </CardLink>
                );
              })}
            </CardContainer>
          </div>
        )}
      </Container>
      {isEventDetailsModalOpen && (
        <EventDetailsModal
          showModal={isEventDetailsModalOpen}
          onClose={handleCloseEventDetailsModel}
          event={actualEvent}
          subscribeConferenceSuccess={subscribeConferenceSuccess}
        />
      )}
    </>
  );
};

export default EventCards;
