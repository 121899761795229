import { PrimaryButton } from '@components/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { styled, Theme, withStyles } from '@material-ui/core/styles';
import Img from 'next/image';
import { Box } from '@material-ui/core';
import { calcSizeBasedInVhScale as calc } from '@utils/metrics';

const calcSizeBasedInVhScale = (size: number) => calc(size, 2.0);

export const Dialog = withStyles(({ breakpoints }) => ({
  root: {
    padding: '0px',
    borderRadius: '15px',
  },
  paper: {
    width: '100%',
    height: '100%',
    borderRadius: '15px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    [breakpoints.down('sm')]: {
      margin: '10px !important',
    },
  },
  paperWidthSm: {
    height: '86%',
    width: '93% !important',
    maxWidth: '100%',
  },
  scrollPaper: {
    justifyContent: 'flex !important',
  },
}))(MuiDialog);

interface DialogContentProps {
  theme: Theme;
  showBgWhite?: boolean;
}

export const DialogContent = styled(MuiDialogContent)(
  (props: DialogContentProps) => ({
    borderRadius: '15px',
    padding: '40px !important',
    backgroundColor: props.showBgWhite ? '#fff' : 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(44px)',
    overflowX: 'hidden',
    overflowY: 'hidden',
    [props.theme.breakpoints.down('sm')]: {
      padding: '40px 20px !important',
    },
  }),
);

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 28px',
  marginTop: '30px',
  overflowY: 'auto',
  overflowX: 'hidden',

  '& p': {
    margin: 0,
    padding: 0,
  },

  '&::-webkit-scrollbar-track': {
    background: '#ffffff',
    border: 'solid 1px #e5e5e5',
  },
  '&::-webkit-scrollbar': {
    width: '25px',
    height: '25px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#f5f5f5',
    border: 'solid 1px #cbcbcb',
    borderRadius: '4px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}));

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  maxHeight: '390px',
  minHeight: '390px',
  marginBottom: '30px',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    minHeight: 'initial',
    maxHeight: 'initial',
    width: 'initial',
    height: 'initial',
  },
}));

export const RowLandingURL = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const BoxImage = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  height: '100%',
  position: 'relative',
  width: '800px',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minHeight: '25vh',
    marginBottom: '20px',
  },
}));

export const Image = styled(Img)({
  borderRadius: '14px',
});
export const Label = styled('span')({});

export const ColumnEvent = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  maxWidth: '400px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginLeft: '40px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5px',
    marginLeft: '0px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontWeight: 'bold',
  fontSize: calcSizeBasedInVhScale(40),

  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',
  },
}));

export const Date = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontWeight: 500,
  fontSize: calcSizeBasedInVhScale(22),

  [theme.breakpoints.down('sm')]: {
    fontWeight: 'bold',
    marginBottom: '20px',
  },
}));

export const Description = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(22),
  maxWidth: '100%',
  display: '-webkit-box',
  '-webkit-line-clamp': 4,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  texOverflow: 'ellipsis',

  [theme.breakpoints.down('sm')]: {
    '-webkit-line-clamp': 3,
    fontSize: '18px',
    maxWidth: '100%',
  },
}));

export const Button = styled(PrimaryButton)({
  height: '40px',
});

export const RowTerms = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const ColumnTerms = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
});

export const Terms = styled('p')(({ theme }) => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(16),
  paddingLeft: '30px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const UncheckIcon = styled('span')({
  width: '1em',
  height: '1em',
  border: '2px solid #acb1c0',
  borderRadius: '8px',
});

export const IconContainer = styled('span')({
  width: '1em',
  height: '1em',
  backgroundColor: '#0080ed',
  bordeRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Error = styled('span')({
  color: 'red',
});
export const BoxIframe = styled(Box)({
  height: '100%',
  width: '100%',
});
export const Iframe = styled('iframe')({
  width: '100%',
  border: '0px',
});
