import { Box, styled, Button as MaterialButton } from '@material-ui/core';

export const ContainerSlider = styled('div')({
  marginBottom: 20,
  '@media screen and (min-width: 1024px)': {
    marginBottom: 40,
  },
});

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',

  '& section': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',

    '& button span': {
      display: 'flex',
      alignItems: 'center',
      margin: 0,
      fontFamily: 'Barlow',
      lineHeight: '22px',
      letterSpacing: 0,
      fontSize: 13,
      fontWeight: 500,
      color: '#333333',
      textTransform: 'none',

      '& svg': {
        fontSize: '1rem',
      },
    },
  },
});

export const ListTitle = styled('h3')(() => ({
  color: '#818181',
  fontSize: '19px',
  lineHeight: '25px',
  fontWeight: 500,
  fontFamily: 'Barlow',
  margin: '0 0 0 20px',
  maxWidth: '60%',
  '@media screen and (min-width: 780px)': {
    fontSize: '24px',
    lineHeight: '27px',
  },
  '@media screen and (min-width: 1100px)': {
    fontSize: 28,
  },
}));

export const Title = styled('h3')(() => ({
  color: '#000',
  fontSize: 24,
  lineHeight: '30px',
  fontWeight: 'bold',
  fontFamily: 'Barlow',
  margin: '0 0 0 20px',
  '@media screen and (min-width: 780px)': {
    margin: '0 0 0 40px',
  },
  '@media screen and (min-width: 1100px)': {
    fontSize: 28,
  },
}));

export const Button = styled(MaterialButton)({
  padding: 0,
  marginRight: 20,
});

export const MarginPage = styled('div')(({ theme }) => ({
  margin: '20px',
  [theme.breakpoints.down('sm')]: {
    margin: '0px',
  },
}));

export const EventMarginPage = styled('div')(({ theme }) => ({
  margin: '-20px 20px',
  [theme.breakpoints.down('sm')]: {
    margin: '-20px 0px -40px',
  },
}));

export const ContainerMarketing = styled(Box)(() => ({
  display: 'flex',
  marginLeft: '40px',
  alignItems: 'flex-end',
  '@media screen and (min-width: 780px)': {
    marginTop: '20px',
    marginRight: '20px',
    '& > div:not(:last-child)': {
      marginRight: '20px',
    },
  },
  '@media screen and (max-width: 780px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 0,
  },
}));

export const TitleUnloggedMobile = styled('h5')(() => ({
  fontFamily: 'Barlow',
  fontSize: '24px',
  margin: '0',
  marginLeft: '20px',
  marginTop: '20px',
  color: '#000',
}));

export const MessageNull = styled('p')(({ theme }) => ({
  paddingLeft: 40,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 20,
  },
}));
