import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { styled, Theme, withStyles } from '@material-ui/core/styles';
import { calcSizeBasedInVhScale } from '@utils/metrics';

export const Dialog = withStyles(({ breakpoints }) => ({
  root: {
    padding: '0px',
    borderRadius: '15px',
  },
  paper: {
    width: '100%',
    height: '100%',
    borderRadius: '15px',
    margin: '16px 32px !important',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    [breakpoints.down('sm')]: {
      margin: '10px !important',
    },
  },
  paperWidthSm: {
    height: '95%',
    width: '500px !important',
    maxWidth: '100%',
  },
  paperWidthXl: {
    height: '98vh !important',
    width: `${calcSizeBasedInVhScale(1050)} !important`,
  },
  scrollPaper: {
    justifyContent: 'flex-end !important',
  },
  paperScrollPaper: {
    maxHeight: '100%',
  },
}))(MuiDialog);

interface DialogContentProps {
  theme: Theme;
  showBgWhite?: boolean;
}

export const DialogContent = styled(MuiDialogContent)(
  (props: DialogContentProps) => ({
    borderRadius: '15px',
    minHight: '100%',
    minWidth: '100%',
    padding: '30px !important',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: props.showBgWhite ? '#fff' : 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(44px)',
    [props.theme.breakpoints.down('sm')]: {
      padding: '40px 20px !important',
    },
  }),
);

export const CloseButton = withStyles(() => ({
  root: {
    zIndex: 99,
    cursor: 'pointer',
    '& svg': {
      width: '30px !important',
      height: '30px !important',
    },
  },
}))(IconButton);
