import CloseIcon from '@material-ui/icons/CloseRounded';

import { LogoStartseBlueIcon } from '@components/core/Icons';

import { Container } from './styles';

import { CloseButton } from '../../styles';

interface HeadProps {
  onClose(): void;
}

const Head: React.FC<HeadProps> = ({ onClose }) => {
  return (
    <Container>
      <LogoStartseBlueIcon />
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="default" />
      </CloseButton>
    </Container>
  );
};

export default Head;
